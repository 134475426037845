import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <div className="contact-wrapper">
      <div
        className="contact-map"
        dangerouslySetInnerHTML={{
          __html: `
              <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.7146533196697!2d121.52166400000002!3d25.043755999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a9710e084319%3A0x3c0ebc820d96fe70!2zMTAw5Y-w5YyX5biC5Lit5q2j5Y2A6Z2S5bO25p2x6LevNC046Jmf!5e0!3m2!1szh-TW!2stw!4v1717421106851!5m2!1szh-TW!2stw"
            width="100%"
            height="100%"
            frameborder="0"
            style="border:0"
            allowfullscreen
          />
        `,
        }}
      />
      <table className="contact-table">
        <tbody>
          <tr>
            <td>地址:</td>
            <td>台北市中正區青島東路4號2樓之7</td>
          </tr>
          <tr>
            <td>電話:</td>
            <td>(02) 2395-6668</td>
          </tr>
          <tr>
            <td>傳真:</td>
            <td>(02) 2395-8008</td>
          </tr>
          <tr>
            <td>地址:</td>
            <td>台北市中正區青島東路4號2樓之7</td>
          </tr>
          <tr>
            <td>E-mail:</td>
            <td>
              <a href="mailto:taia2.mail@msa.hinet.net">
                taia2.mail@msa.hinet.net
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Layout>
)

export default Contact
